<template>
  <div class="row">
    <retry-notification
      :show="error"
      @retry="updateData()"
    />

    <div
      v-if="currentUser.can('TeamsYearPlans', 'add')"
      class="flex xs12"
    >
      <va-card>
        <div class="row align--center">
          <div class="flex xs12 md10">
            <h4>{{$t('teams.new')}}</h4>
          </div>
          <div class="flex xs12 md2 pull-right">
            <va-button
              color="primary"
              icon="fa fa-plus"
              :to="{ name: 'teamsInscription' }"
            >
              {{ $t('teams.inscriptions.tooltip') }}
            </va-button>
          </div>
        </div>
      </va-card>
    </div>

    <div class="flex xs12">
      <va-collapse withBackground>
        <span slot="header">{{ $t('layout.filters.label') }}</span>
        <template slot="body">
          <filters
            :loading="loading"
            :actions="filterActions"
            @filter="filterData"
            @clear-filters="filterData(null)"
          />
        </template>
      </va-collapse>
    </div>

    <div class="flex xs12">
      <va-card :title="$t('teams.index')">
        <div v-show="totalTeams > 0" slot="actions">
          <span>{{ $t('teams.total_registered', { total: totalTeams }) }}</span>
        </div>
        <remote-table
          class="table-hover table-striped"
          :columns="tableFields"
          :data="tableData"
          :loading="loading"
          :pagination="pagination"
          :top-options="tableActions"
          :params="serverParams"
          :queries="serverParams.queries"
          :search="searchOptions"
          :sortField="sort.field"
          :sortType="sort.type"
          crud-links="teams"
          @update-data="updateData"
          @search-data="searchByName"
          @delete-item="tryDelete"
          @download-item="prepareDownload"
        >
          <template v-slot:row-status="{ props }">
            <va-badge :color="getStatusColor(props.rowData.status)">
              {{ $t(getStatusLabel(props.rowData.status)) }}
            </va-badge>
          </template>
        </remote-table>
      </va-card>
    </div>

    <div
      v-if="currentUser.can('TeamsYearPlans', 'download')"
      class="flex xs12"
    >
      <va-card :title="$t('teams.generate.annual_plan')">
        <yearly-bar
          selection="district"
          :loading="loading"
          @submit="prepareDownloadPlan"
        />
      </va-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const Filters = () => import(/* webpackPrefetch: true */ '@/components/extras/Filters')
const YearlyBar = () => import(/* webpackPrefetch: true */ '@/components/extras/Bars/Yearly')
const RemoteTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/RemoteTable')

export default {
  name: 'teams-index',
  components: {
    Filters,
    YearlyBar,
    RemoteTable,
  },
  data () {
    return {
      error: false,
      tableData: [],
      pagination: {},
      searchQuery: '',
      loading: false,
      searchOptions: {
        enabled: true,
        trigger: 'enter',
        placeholder: this.$t('tables.actions.search'),
      },
      tableActions: [],
      totalTeams: 0,
      sort: {
        field: 'code',
        type: 'asc',
      },
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: 'code',
          type: 'asc',
        },
        queries: '',
        page: 1,
        perPage: 50,
      },
      filterActions: ['regions', 'countries', 'districts', 'teamStatus'],
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    tableFields () {
      return [
        {
          name: '__slot:status',
          title: this.$t('tables.headings.status'),
        },
        {
          name: 'code',
          title: this.$t('tables.headings.code'),
          sortField: 'code',
        },
        {
          name: 'leader.name',
          title: this.$t('tables.headings.leader'),
          sortField: 'Leaders.name',
        },
        {
          name: 'country.name',
          title: this.$t('tables.headings.country'),
          sortField: 'Countries.name',
          callback: this.translatedName,
        },
        {
          name: 'district.name',
          title: this.$t('tables.headings.district'),
          sortField: 'Districts.name',
          callback: this.translatedName,
        },
        {
          name: '__slot:actions',
          hidden: this.tableData.length === 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
  },
  methods: {
    translatedName (name) {
      return this.$t(name)
    },
    getStatusColor (status) {
      let color = 'primary'
      switch (status) {
        case 0: color = 'success'; break
        case -1: color = 'danger'; break
        case 2: color = 'dark'; break
      }
      return color
    },
    getStatusLabel (status) {
      let label = 'teams.status.renewed'
      switch (status) {
        case 0: label = 'teams.status.new'; break
        case 2: label = 'teams.status.Registered'; break
        case 3: label = 'teams.status.notrenewd'; break
        case -1: label = 'teams.status.removed'; break
      }
      return label
    },
    fixData (data) {
      // let noDef = this.$t('tables.undefined')
      for (const d of data) {
        if (!d.leader) {
          d.leader = {
            name: '',
          }
        }
        if (!d.country) {
          d.country = {
            name: '',
          }
        }
        if (!d.district) {
          d.district = {
            name: '',
          }
        }
      }
      return data
    },
    filterData (filters) {
      if (!filters) {
        this.serverParams.queries = ''
        return
      }

      let queries = ''
      if (filters.region) {
        queries += '&region=' + filters.region
      }
      if (filters.country) {
        queries += '&country=' + filters.country
      }
      if (filters.district) {
        queries += '&district=' + filters.district
      }
      if (Number.isInteger(filters.teamStatus)) {
        queries += '&status=' + filters.teamStatus
      }

      this.serverParams.queries = queries
    },
    apiUrl (params) {
      let route = 'teams'
      route += '?page=' + params.page || 0
      route += '&limit=' + params.perPage || 50
      if (params.sort && params.sort.field !== '') {
        let field = params.sort.field
        switch (field) {
          case 'leader.name':
            field = 'Leaders.name'
            break
          case 'country.name':
            field = 'Countries.name'
            break
          case 'district.name':
            field = 'Districts.name'
            break
        }
        route += '&sort=' + field
        route += '&direction=' + params.sort.type
      }
      if (params.queries) {
        route += params.queries
      }
      if (params.search) {
        route += '&q=' + params.search
      }

      return route
    },
    async updateData (params) {
      params = params || this.serverParams

      this.loading = true
      this.error = false

      let response = false
      try {
        response = await this.$http.get(this.apiUrl(params))
      } catch (e) {
        // console.log(e)
        this.showToast(this.$t('notifications.network.error'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.loading = false
        this.error = true
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.pagination = response.data.pagination
      this.totalTeams = this.pagination.count || 0
      this.loading = false
    },
    async searchByName (name) {
      if (this.serverParams.search !== name) {
        this.serverParams.page = 1
      }
      this.serverParams.search = name
      this.loading = true
      this.error = false

      let response = false
      try {
        response = await this.$http.get(this.apiUrl(this.serverParams))
      } catch (err) {
        this.showToast(this.$t('notifications.network.error'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.loading = false
        this.error = true
        return
      }
      if (this.serverParams.search !== name) {
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.pagination = response.data.pagination
      this.loading = false
    },
    async tryDelete (item) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('layout.buttons.confirm'),
        cancelButtonText: this.$t('layout.buttons.cancel'),
      })

      if (result.value) {
        this.loading = true
        try {
          await this.$http.delete('teams/' + item.id)
        } catch (e) {
          // console.log('Error', e)
          this.loading = false
        }

        this.loading = false
        this.updateData()
      }
    },
    prepareDownload (format) {
      const prepareRoute = 'teams/download'
      const downloadRoute = 'teams/download/'

      return this.downloadFile(format, prepareRoute, downloadRoute)
    },
    prepareDownloadPlan (filters) {
      const prepareRoute = 'teams/planning/download'
      const downloadRoute = 'teams/planning/download/'

      return this.downloadFile({ data: filters }, prepareRoute, downloadRoute)
    },
    async downloadFile (filters, prepareRoute, downloadRoute) {
      let fileName = ''
      const prepare = {
        icon: 'info',
        title: this.$t('notifications.download.prepare'),
        confirmButtonText: this.$t('notifications.download.button'),
        text: this.$t('notifications.download.wait'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false
          try {
            data = await this.$http.post(prepareRoute, filters)
          } catch (e) {
            if (e.response.data.data === 'no_data') {
              this.$swal.insertQueueStep(noData)
            } else {
              this.$swal.insertQueueStep(error)
            }
            return
          }

          fileName = data.data.data
          this.$swal.insertQueueStep(download)
        },
      }
      const error = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.error'),
      }
      const noData = {
        icon: 'error',
        title: 'Error',
        text: this.$t('teams.generate.no_data'),
      }
      const download = {
        icon: 'success',
        title: this.$t('notifications.download.downloading'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()
          let response = false
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: 'blob',
            })
          } catch (e) {
            // console.log('Cant download file', e)
            await this.$swal.queue([retry])
            return
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          this.$swal.close()
        },
      }
      const retry = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.failed'),
        confirmButtonText: this.$t('notifications.download.retry'),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download)
        },
      }

      this.$swal.queue([prepare])
    },
  },
}
</script>
